// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Chart21,
  Graph,
  Dash,
  HomeTrendUp,
  Headphone,
  Personalcard,
  People,
  Flag,
  Activity,
  User,
  Tag2
} from 'iconsax-react';

import PieChartIcon from '@mui/icons-material/PieChart';
// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  charts: Chart21,
  chart: Graph,
  dash: Dash,
  pieChart: PieChartIcon,
  chartdash: HomeTrendUp,
  channels: Headphone,
  department: Personalcard,
  contactgroup: People,
  campaigns: Flag,
  campaignsdashboard: Activity,
  user: User,
  tags: Tag2
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType[] = [
  {
    id: 'group-atendimento',
    // icon: icons.atendimento,
    type: 'group',
    children: [
      {
        id: 'react-chat',
        title: <FormattedMessage id="Atendimento" />, // Gerenciamento e histórico de chats
        type: 'collapse',
        icon: icons.chat,
        children: [
          {
            id: 'chat',
            title: <FormattedMessage id="Bate papo" />,
            type: 'item',
            url: '/apps/chat'
          },
          {
            id: 'atendimento',
            title: <FormattedMessage id="Dashboard Atendimento" />, // Monitoramento em tempo real e relatórios de atendimento
            type: 'item',
            url: '/apps/atendimento'
          }
        ]
      }
    ]
  },
  {
    id: 'group-contatos',
    // icon: icons.contatos,
    type: 'group',
    children: [
      {
        id: 'react-contatos',
        title: <FormattedMessage id="Contatos" />, // Importação, exportação, e segmentação de contatos
        type: 'collapse',
        icon: icons.profile,
        children: [
          {
            id: 'contatos',
            title: <FormattedMessage id="Lista de contatos" />,
            type: 'item',
            url: '/apps/contatos/lista'
          },
          {
            id: 'grupocontatos',
            title: <FormattedMessage id="Grupo Contatos" />, // Criação e gestão de grupos de contatos
            type: 'item',
            url: '/apps/contatos/grupos'
          },
          {
            id: 'dashboardcontatos',
            title: <FormattedMessage id="Dashboard Contatos" />, // Relatórios e visualização de dados de contatos
            type: 'item',
            url: '/apps/dashboard-contatos'
          }
        ]
      }
    ]
  },
  {
    id: 'group-canais',
    // icon: icons.canais,
    type: 'group',
    children: [
      {
        id: 'react-canais',
        title: <FormattedMessage id="Canais" />,
        type: 'collapse',
        icon: icons.channels,
        children: [
          {
            id: 'canais',
            title: <FormattedMessage id="Gerenciamento de canais" />, // Gerenciamento de canais (WhatsApp, Facebook, Instagram, etc.)
            type: 'item',
            url: '/apps/canais'
          },
          {
            id: 'configuracao-canais',
            title: <FormattedMessage id="Adicionar/remover canais" />, // Adicionar/Remover canais e configurar
            type: 'item',
            url: '/apps/configuracao-canais'
          }
        ]
      }
    ]
  },
  {
    id: 'group-departamentos',
    // icon: icons.departamento,
    type: 'group',
    children: [
      {
        id: 'react-departamentos',
        title: <FormattedMessage id="Departamentos" />,
        type: 'collapse',
        icon: icons.department,
        children: [
          {
            id: 'departamento',
            title: <FormattedMessage id="Gestão de departamentos" />, // Gestão de departamentos e operadores
            type: 'item',
            url: '/apps/departamento'
          },
          {
            id: 'monitoramento-departamento',
            title: <FormattedMessage id="Dashboard de desempenho" />, // Monitoramento de desempenho por departamento
            type: 'item',
            url: '/apps/monitoramento-departamento'
          }
        ]
      }
    ]
  },
  {
    id: 'group-campanhas',
    // icon: icons.campanhas,
    type: 'group',
    children: [
      {
        id: 'react-campanhas',
        title: <FormattedMessage id="Campanhas" />,
        type: 'collapse',
        icon: icons.campaigns,
        children: [
          {
            id: 'campanhas',
            title: <FormattedMessage id="Cadastro de campanha" />, // Criação e edição de campanhas
            type: 'item',
            url: '/apps/campanhas'
          },
          {
            id: 'dashboardcampanhas',
            title: <FormattedMessage id="Dashboard de campanha" />, // Monitoramento e análise de campanhas ativas
            type: 'item',
            url: '/apps/dashboard-campanhas'
          }
        ]
      }
    ]
  },
  {
    id: 'group-usuarios',
    icon: icons.user,
    type: 'group',
    children: [
      {
        id: 'react-usuarios',
        title: <FormattedMessage id="Usuários" />,
        type: 'collapse',
        icon: icons.user,
        children: [
          {
            id: 'usario',
            title: <FormattedMessage id="Gerenciar usuários" />, // Gestão de perfis, permissões e acessos
            type: 'item',
            url: '/apps/usuario'
          }
        ]
      }
    ]
  },
  {
    id: 'group-tags',
    icon: icons.tags,
    type: 'group',
    children: [
      {
        id: 'react-tags',
        title: <FormattedMessage id="Tags" />,
        type: 'collapse',
        icon: icons.tags,
        children: [
          {
            id: 'tags',
            title: <FormattedMessage id="Criação e edição de tags" />, // Criação, edição e gerenciamento de tags para segmentação
            type: 'item',
            url: '/apps/tags'
          }
        ]
      }
    ]
  }
];

export default applications;
