export enum WebsocketEvent {
  Disconnect = 'disconnect',
  ChatbotMessage = 'chatbot_message',
  ChatbotSendMessage = 'chatbot_send_message',
  ChatbotQueue = 'chatbot_queue',
  ChatbotSendSeen = 'chatbot_send_seen',
  ChatbotMessageUnread = 'chatbot_message_unread',
  ChatbotSendReply = 'chatbot_send_reply',
  ChatbotSendMessageRevoke = 'chatbot_send_message_revoke',
  ChatbotMessageRevoke = 'chatbot_message_revoke',
  ChatbotSendFile = 'chatbot_send_file',
  ChatbotSendMyContact = 'chatbot_send_my_contact',
  ChatbotSendContact = 'chatbot_send_contact',
  ChatbotSendError = 'chatbot_send_error',
  ChatbotResponseFile = 'chatbot_response_file',
  ChatbotSendPtt = 'chatbot_send_ptt',
  ChatbotMessageAck = 'chatbot_message_ack',
  ChatbotSendReaction = 'chatbot_send_reaction',
  ChatbotMessageReaction = 'chatbot_message_reaction',
  ChatBotSendMessageEdit = 'chatbot_send_message_edit',
  ChatBotMessageEdit = 'chatbot_message_edit'
}
