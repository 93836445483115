import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Box, Typography } from '@mui/material';
import { getItemLocalStorage } from 'hooks/useLocalStorage';
import { useMemo, useState } from 'react';
import ConfirmModal from 'sections/apps/chat/ConfirmModal';
import { useSelector } from 'store';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const DisconnectedModal = () => {
  const [open, setOpen] = useState(false);
  const socketState = useSelector((state) => state.socket);
  const accessToken = getItemLocalStorage('accessToken');

  const handleReconnect = () => {
    window.location.reload();
  };

  useMemo(() => {
    if (!socketState.connected && !!accessToken) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return (
    <Box
      zIndex={'1000000'}
      width={'400px'}
    >
      <ConfirmModal
        open={open}
        onConfirm={handleReconnect}
        title={<Typography variant="h5">Sua conexão foi Perdida</Typography>}
        description={
          <Box
            width={'100%'}
            textAlign={'center'}
          >
            <WifiOffIcon
              color="error"
              sx={{ fontSize: '10rem' }}
            />
          </Box>
        }
        confirmMesssage="Reconectar"
      />
    </Box>
  );
};

export default DisconnectedModal;
